import React, { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
} from '@mui/material';
import CompleteButton from '../shared/CompleteButton';
import CancelButton from '../shared/CancelButton';
import towers from '../../../src/towers.json';
import api from 'src/services/BaseService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px'
};

export default function EditMuhaberatOutputPost({ post, onClose }) {
    const [barcode, setBarcode] = useState(post.barcode || '');
    const [customerName, setCustomerName] = useState(post.dataJSON.customerName || '');
    const [branchTitle, setBranchTitle] = useState(post.branchTitle || '');
    const [tower, setTower] = useState(post.dataJSON.tower || '');
    const [office, setOffice] = useState(post.dataJSON.office || '');
    const [packageCount, setPackageCount] = useState(post.dataJSON.packageCount || '');
    const [shipmentFirm, setShipmentFirm] = useState(post.dataJSON.shipmentFirm || '');
    const [branchOptions, setBranchOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    const shipmentFirmOptions = [
        'Aras Kargo',
        'MNG Kargo',
        'PTT Kargo',
        'Sürat Kargo',
        'UPS',
        'Yurtiçi Kargo',
        'Trendyol Express',
        'Hepsi Jet',
        'Kolaygelsin',
        'Kargoist',
        'Sendeo',
        'DHL',
        'Fedex',
        'Birgünde Kargo',
        'Beymen',
        'Kargom Sende',
        'Scotty',
        'AGT',
        'Bovo',
        'Bolt',
        'Diğer',
    ];

    const towerOptions = {
        ...towers.kule,
        ...towers.teras,
        ...towers.ofis
    };

    const officeOptions = tower && towerOptions[tower] ? towerOptions[tower] : [];

    const handleSubmit = () => {
        console.log({
            barcode,
            customerName,
            branchTitle,
            tower,
            office,
            packageCount,
            shipmentFirm
        });
    };

    useEffect(() => {
        const mall = localStorage.getItem('mallID');
        api
            .post('flow/get-malls-branches', {
                mallID: mall,
            })
            .then((response) => {
                if (response.data.status) {
                    const result = response.data.result;
                    const processedDataList = result?.map((item) => ({
                        value: item.title,
                        id: item.branchID,
                        corporationID: item.corporationID ? item.corporationID : '-',
                        locationID: item.locations[0].locationID,
                    }));
                    setBranchOptions(processedDataList);
                } else {
                    toast.error(
                        'Müşteri listesi çekilirken hata oluştu, bağlantınızı kontrol ediniz.'
                    );
                    setBranchOptions([]);
                }
                setLoading(false)
            })
            .catch(() => {
                toast.error(
                    'Müşteri listesi çekilirken hata oluştu, bağlantınızı kontrol ediniz.'
                );
                setLoading(false)
                setBranchOptions([]);
            });
    }, []);

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <Box sx={style}>
                <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{post.barcode} Barkodlu Gönderiyi Düzenle</Typography>
                <TextField
                    label="Barkod"
                    value={barcode}
                    onChange={(e) => setBarcode(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Kişi"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="branch-title-label">Firma</InputLabel>
                    <Select
                        disabled={loading}
                        labelId="branch-title-label"
                        label="Firma"
                        value={branchTitle}
                        onChange={(e) => setBranchTitle(e.target.value)}
                    >
                        {branchOptions.map((option) => (
                            <MenuItem key={option.id} value={option.value}>{option.value}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="tower-label">Kule/Teras/Ofis</InputLabel>
                    <Select
                        labelId="tower-label"
                        label="Kule/Teras/Ofis"
                        value={tower}
                        onChange={(e) => setTower(e.target.value)}
                    >
                        {Object.keys(towerOptions).map((option) => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="office-label">Daire</InputLabel>
                    <Select
                        disabled={!tower}
                        label="Daire"
                        labelId="office-label"
                        value={office}
                        onChange={(e) => setOffice(e.target.value)}
                    >
                        {officeOptions.map((option) => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Paket Sayısı"
                    type="number"
                    value={packageCount}
                    onChange={(e) => setPackageCount(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="shipment-firm-label">Kargo Firması</InputLabel>
                    <Select
                        labelId="shipment-firm-label"
                        label="Kargo Firması"
                        value={shipmentFirm}
                        onChange={(e) => setShipmentFirm(e.target.value)}
                    >
                        {shipmentFirmOptions.map((option) => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <div style={{ width: '100%', justifyContent: 'flex-end', display: 'flex', marginTop: '15px', gap: '10px' }}>
                    <CancelButton label={'Vazgeç'} onClick={onClose} />
                    <CompleteButton label={"Kaydet"} onClick={handleSubmit} btnType={'save'} />
                </div>
            </Box>
        </Modal>
    );
}
