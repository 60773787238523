import { Backdrop, Button, Card, CircularProgress, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import Iconify from 'src/components/iconify';
import Services from 'src/services/Services';
import FilterButton from 'src/components/shared/FilterButton';
import ClearButton from 'src/components/shared/ClearButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import { toast } from 'react-toastify';
import ExportToExcelButton from 'src/components/shared/ExportToExcelButton';
import api from 'src/services/BaseService';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import DonutChart from 'src/components/chart/DonutChart';
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function SafeBoxOperations() {
    const StyledIcon = styled('div')(({ theme }) => ({
        display: 'flex',
        borderRadius: '5px',
        alignItems: 'center',
        width: theme.spacing(8),
        height: theme.spacing(8),
        justifyContent: 'center',
    }));



    const parsedUser = JSON.parse(localStorage.getItem('user'));
    const token = parsedUser.token

    const isMobile = useMediaQuery('(max-width:768px)');
    const [filterModal, setFilterModal] = useState(false);

    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [pieData, setPieData] = useState([0, 0]);
    const [totalCount, setTotalCount] = useState(0);
    const [availableCount, setAvailableCount] = useState(0);
    const [unavailableCount, setUnavailableCount] = useState(0);
    const [percantage, setPercantage] = useState(0);
    const [loading, setLoading] = useState(false);

    const [filterLocationCode, setFilterLocationCode] = useState("");
    const [filterLockerNo, setFilterLockerNo] = useState("");
    const [filterSelectedLocation, setFilterSelectedLocation] = useState("");

    const [dailyCount, setDailyCount] = useState(0);


    const [dropBoxList, setDropBoxList] = useState([]);
    const [originalData, setOriginalData] = useState([]);

    let TABLE_HEAD = [
        { id: 'locationCode', label: 'Lokasyon Kodu' },
        {
            id: 'location', label: 'Lokasyon', format: (value, row) => {
                if (row.locationCode === "AA120") {
                    return (
                        <div>Zorlu Center - Metro Giris</div>
                    )
                }
                else {
                    return (
                        <div>Zorlu Center - Bogaz Kati</div>
                    )
                }
            },
            sortFunction: (row) => {
                if (row.locationCode === "AA120") {
                    return 1;
                } else {
                    return 2;
                }
            }
        },
        { id: 'duration', label: 'Kullanım Süresi', format: (value, row) => <div>{calculateHours(row.startDate, row.endDate)} Saat</div>, sortFunction: (row) => calculateHours(row.startDate, row.endDate) },
        {
            id: 'startDate', label: 'Başlangıç Tarihi', format: (value) => formatDate(value)
        },
        {
            id: 'endDate', label: 'Bitiş Tarihi', format: (value) => formatDate(value)
        },
        { id: 'lockerNumber', label: 'Dolap No', sortFunction: (row) => parseInt(row.lockerNumber, 10) },
        { id: 'price', label: 'Ücret', sortFunction: (row) => parseInt(row.price, 10), format: (value) => <div>{value} ₺</div> }
    ];

    const handleCloseModal = () => {
        setFilterModal(false);
    };

    const handleModal = () => {
        setFilterModal(true);
    };

    const dateDifference = (date1, date2) => {
        return date2.diff(date1, 'days'); // 'days' parametresi gün cinsinden farkı verir
    };


    function calculateHours(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const differenceInMs = end - start;
        const differenceInHours = differenceInMs / (1000 * 60 * 60);
        return differenceInHours.toFixed(0); // İki ondalık basamak kullanarak sonucu döndürüyoruz
    }

    function formatDate(value) {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Aylar 0 indexli olduğu için +1 ekliyoruz.
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    const clearListOptions = () => {
        // setSelectedLocation('')
        setStartDate(null)
        setEndDate(null)
        setDropBoxList([])

        setPieData([0, 0]);
        setTotalCount(0);
        setAvailableCount(0);
        setUnavailableCount(0);
        setPercantage(0);
        setDailyCount(0)
    }

    const handleChangeFilterLocation = (event) => {
        const {
            target: { value },
        } = event;
        setFilterSelectedLocation(value);
    };

    // const handleChange = (event) => {
    //     setSelectedLocation(event.target.value);
    // };

    // const getDropBoxLocations = () => {
    //     Services.GetDropboxLocations(token)
    //         .then((res) => {
    //             console.log(res.data);
    //             if (res.data.status) {
    //                 setLocations(res.data.result)
    //             } else {

    //             }
    //         })
    //         .catch((err) => console.log(err));
    // }

    const getDropboxListByLocations = async () => {
        setLoading(true);
        const locationCodes = ["AA120", "AA121"]; // İki farklı location code


        try {
            const results = await Promise.all(
                locationCodes.map((code) =>
                    Services.GetDropboxListByLocations(
                        token,
                        code,
                        startDate.format("YYYY-MM-DD"),
                        endDate.format("YYYY-MM-DD")
                    )
                )
            );

            const combinedResult = results.reduce(
                (acc, res) => {
                    if (res.data.status) {
                        acc.unAvailableCount += res.data.result.unAvailableCount;
                        acc.availableCount += res.data.result.availableCount;
                        acc.totalCount += res.data.result.totalCount;
                        acc.transactions = acc.transactions.concat(
                            res.data.result.transactions
                        );
                    } else {
                        toast.error(`Veri bulunamadı: ${res.data.locationCode}.`);
                    }
                    return acc;
                },
                { unAvailableCount: 0, availableCount: 0, totalCount: 0, transactions: [] }
            );

            const percantageInUse =
                (combinedResult.unAvailableCount / combinedResult.totalCount) * 100;



            let dayCount = dateDifference(startDate, endDate) + 1

            setDailyCount(combinedResult.transactions.length / dayCount)
            setPieData([combinedResult.unAvailableCount, combinedResult.availableCount]);
            setDropBoxList(combinedResult.transactions);
            setOriginalData(combinedResult.transactions)
            setTotalCount(combinedResult.totalCount);
            setAvailableCount(combinedResult.availableCount);
            setUnavailableCount(combinedResult.unAvailableCount);
            setPercantage(percantageInUse.toFixed(2));
        } catch (err) {
            console.log(err);
            toast.error("Veriler getirilirken bir hata oluştu.");
        } finally {
            setLoading(false);
        }
    };


    function exportToExcel() {
        let temp1 = dropBoxList.map((x, index) => ({
            id: index + 1,
            locationCode: x.locationCode,
            location: x.locationCode === "AA120" ? "Zorlu Center - Metro Giris" : "  Zorlu Center - Bogaz Kati",
            duration: `${calculateHours(x.startDate, x.endDate)} Saat`,
            startDate: formatDate(x.startDate),
            endDate: formatDate(x.endDate),
            lockerNumber: x.lockerNumber,
            price: `${x.price} ₺`
        }));

        api
            .post('excel/export', {
                type: 'excel',
                content: temp1,
                header: {
                    No: 'number',
                    'Lokasyon Kodu': 'string',
                    'Lokasyon': 'string',
                    'Kullanım Süresi': 'string',
                    'Başlangıç Tarihi': 'string',
                    'Bitiş Tarihi': 'string',
                    'Dolap No': 'string',
                    'Ücret': 'string',
                },
                sheetTitle: 'Emanet Dolaplar',
            })
            .then((response) => {
                // console.log(response.data);
                if (response.data.status) {
                    try {
                        const newWindow = window.open(response.data.result, '_blank');
                        if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
                            setShowPopUpError(true)
                            throw new Error('Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.');
                        }
                    } catch (e) {
                        setShowPopUpError(true)
                    }
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                // console.log(error);
                toast.error(error.message);
            });
    }

    // useEffect(() => {
    //     getDropBoxLocations()
    // }, []);

    const clearFilters = () => {
        setDropBoxList(originalData)
        setFilterLocationCode("")
        setFilterLockerNo("")
        setFilterSelectedLocation("")
    };

    const getFilteredDropboxList = () => {
        let filteredDropboxList;
        if (
            filterLocationCode ||
            filterLockerNo ||
            filterSelectedLocation
        ) {
            filteredDropboxList = originalData.filter((item) => {
                const locationCodeMatch = filterLocationCode
                    ? item.locationCode.toLowerCase().includes(filterLocationCode.toLowerCase())
                    : true;
                const lockerNoMatch = filterLockerNo
                    ? item.lockerNumber === filterLockerNo
                    : true;
                const selectedLocationMatch = filterSelectedLocation
                    ? item.locationCode.toLowerCase().includes(filterSelectedLocation.toLowerCase())
                    : true;


                return (
                    locationCodeMatch &&
                    lockerNoMatch &&
                    selectedLocationMatch
                );
            });
        } else {
            filteredDropboxList = originalData;
        }

        setDropBoxList(filteredDropboxList);
    };

    useEffect(() => {
        getDropboxListByLocations()
    }, []);

    return (
        <>
            <Helmet>
                <title> Emanet İşlemleri | Mall Logistics Panel </title>
            </Helmet>
            <Stack style={{ paddingInline: '25px' }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Emanet İşlemleri
                    </Typography>
                </Stack>

                <Stack
                    direction="row"
                    justifyContent={isMobile ? 'space-between' : 'end'}
                    mb={5}
                >
                    {isMobile ? <FilterViewButton variant="contained" onClick={handleModal} /> : null}

                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <ExportToExcelButton onClick={exportToExcel} />
                    </div>

                </Stack>


                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={4}>
                        <Card sx={{ height: '144px', alignItems: 'center', display: 'flex', flexDirection: 'row', paddingInline: '32px', justifyContent: 'space-between' }}>

                            <div style={{ flexDirection: 'column', display: 'flex' }}>
                                <Typography sx={{ fontSize: '18px', fontWeight: '600', color: '#1F2A37', fontFamily: 'Inter' }}>Göz Adedi</Typography>
                                <span style={{ fontSize: '16px', fontWeight: '500', color: '#9CA3AF', fontFamily: 'Inter' }}>Toplam : <span style={{ color: '#6B7280' }}>{totalCount}</span></span>
                                <span style={{ fontSize: '16px', fontWeight: '500', color: '#9CA3AF', fontFamily: 'Inter' }}>Kullanımda : <span style={{ color: '#6B7280' }}>{unavailableCount}</span></span>
                            </div>


                            <StyledIcon
                                sx={{
                                    color: 'white',
                                    backgroundImage: (theme) =>
                                        `linear-gradient(135deg, #203B80 0%, #3A5EB9 100%)`
                                }}
                            >
                                <Iconify icon={'cuida:layers-outline'} width={24} height={24} />
                            </StyledIcon>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Card sx={{ height: '144px' }}>
                            <Card sx={{ height: '144px', alignItems: 'center', display: 'flex', flexDirection: 'row', paddingInline: '32px', justifyContent: 'space-between' }}>

                                <div style={{ flexDirection: 'column', display: 'flex' }}>
                                    <Typography sx={{ fontSize: '18px', fontWeight: '600', color: '#1F2A37', fontFamily: 'Inter' }}>Doluluk Oranı</Typography>
                                    <span style={{ fontSize: '16px', fontWeight: '500', color: '#9CA3AF', fontFamily: 'Inter' }}>Toplam : <span style={{ color: '#6B7280' }}>{totalCount}</span></span>
                                    <span style={{ fontSize: '16px', fontWeight: '500', color: '#9CA3AF', fontFamily: 'Inter' }}>Kullanımda : <span style={{ color: '#6B7280' }}>{unavailableCount}</span></span>
                                    <span style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '12px', height: '12px', borderRadius: '50px', backgroundColor: '#F7A937' }}></div>
                                        <span style={{ fontSize: '12px', fontWeight: '500', color: '#1E1B39', fontFamily: 'Inter', marginLeft: '6px' }}>Boşta</span>

                                        <div style={{ width: '12px', height: '12px', borderRadius: '50px', backgroundColor: '#3150A0', marginLeft: '13px' }}></div>
                                        <span style={{ fontSize: '12px', fontWeight: '500', color: '#1E1B39', fontFamily: 'Inter', marginLeft: '6px' }}>Kullanımda</span>
                                    </span>
                                </div>

                                {dropBoxList.length > 0 && <DonutChart unavailable={unavailableCount} available={availableCount} />}

                            </Card>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Card sx={{ height: '144px' }}>
                            <Card sx={{ height: '144px', alignItems: 'center', display: 'flex', flexDirection: 'row', paddingInline: '32px', justifyContent: 'space-between' }}>

                                <div style={{ flexDirection: 'column', display: 'flex' }}>
                                    <Typography sx={{ fontSize: '18px', fontWeight: '600', color: '#1F2A37', fontFamily: 'Inter' }}>Günlük Kullanım Adedi</Typography>
                                    <span style={{ fontSize: '16px', fontWeight: '500', color: '#9CA3AF', fontFamily: 'Inter' }}>Toplam : <span style={{ color: '#6B7280' }}>{dropBoxList.length}</span></span>
                                    <span style={{ fontSize: '16px', fontWeight: '500', color: '#9CA3AF', fontFamily: 'Inter' }}>Ortalama : <span style={{ color: '#6B7280' }}>{dailyCount.toFixed(1)}</span></span>
                                </div>


                                <StyledIcon
                                    sx={{
                                        color: 'white',
                                        backgroundImage: (theme) =>
                                            `linear-gradient(135deg, #203B80 0%, #3A5EB9 100%)`
                                    }}
                                >
                                    <Iconify icon={'charm:chart-bar'} width={24} height={24} />
                                </StyledIcon>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>

                <Card sx={{ padding: '25px', marginTop: '32px' }}>
                    <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        {/* <FormControl sx={{ width: '255px' }}>
                            <InputLabel id="select-label">Lokasyon</InputLabel>
                            <Select
                                disabled={locations.length === 0}
                                labelId="select-label"
                                value={selectedLocation}
                                onChange={handleChange}
                                label="Lokasyon"
                            >
                                {locations.map((item) => (
                                    <MenuItem key={item.locationCode} value={item}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
                        <div style={{ flexDirection: 'row', display: 'flex', gap: '15px', alignItems: 'center' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} md={4} xl={4}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            label="Başlangıç Tarihi"
                                            value={startDate}
                                            onChange={(newValue) => {
                                                setStartDate(newValue);
                                                setEndDate(null);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} xl={4}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            label="Bitiş Tarihi"
                                            value={endDate}
                                            minDate={startDate}
                                            disabled={!startDate}
                                            onChange={(newValue) => setEndDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} xl={4} sx={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
                                    <Button sx={{
                                        height: '40px',
                                        fontWeight: 400,
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        letterSpacing: 0.4,
                                    }} disabled={!startDate || !endDate} variant='contained' onClick={() => getDropboxListByLocations()}>Listele</Button>
                                    <ClearButton onClick={clearListOptions} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>


                </Card>

                <Card sx={{ marginTop: '32px' }}>



                    {loading ? (
                        <Backdrop sx={{ color: '#fff', zIndex: 2, height: '100%' }} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    ) : (
                        <DynamicTable
                            data={dropBoxList}
                            headers={TABLE_HEAD}
                            isOrdered={false}
                            isMobile={isMobile}
                            initialOrder={"startDate"}
                            initalOrderDirection={"desc"}
                            handleModal={dropBoxList.length > 0 ? handleModal : null}
                            handleCloseModal={handleCloseModal}
                        />
                    )}

                </Card>

                <Card>
                    <CustomFilter modalType={'drawer'} filterModal={filterModal} handleCloseModal={handleCloseModal}>
                        <>
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="space-between"
                                paddingY={2}
                                paddingX={2}
                                gap={2}
                            >

                                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                                    <InputLabel id="priority-store-label">Lokasyon</InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        value={filterSelectedLocation}
                                        onChange={handleChangeFilterLocation}
                                        input={<OutlinedInput label="Lokasyon" />}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem key={"AA120"} value={"AA120"}>
                                            Zorlu Center - Metro Giris
                                        </MenuItem>
                                        <MenuItem key={"AA121"} value={"AA121"}>
                                            Zorlu Center - Bogaz Kati
                                        </MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                                    <TextField
                                        label="Lokasyon Kodu"
                                        variant="outlined"
                                        value={filterLocationCode}
                                        onChange={(e) => setFilterLocationCode(e.target.value)}

                                    />
                                </FormControl>

                                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                                    <TextField
                                        label="Dolap No"
                                        variant="outlined"
                                        value={filterLockerNo}
                                        onChange={(e) => setFilterLockerNo(e.target.value)}

                                    />
                                </FormControl>


                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    sx={{ width: '50%' }}
                                >
                                    <ClearButton
                                        onClick={clearFilters}
                                        sx={{ marginRight: '8px' }}
                                    />
                                    <FilterButton onClick={getFilteredDropboxList} />
                                </Stack>
                            </Stack>
                        </>
                    </CustomFilter>

                </Card>
            </Stack>
        </>
    )
}
