import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
// @mui
import {
  Card,
  Stack,
  Typography,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Divider,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//shared
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import AddEntityButton from 'src/components/shared/AddEntityButton';
import EditButton from 'src/components/shared/EditButton';
import DynamicTable from 'src/components/shared/DynamicTable';
// sections
import Services from 'src/services/Services';
import './muhaberatOutput.css';
import SupportTicketsConversation from './Views/SupportTicketsConversation';
import { tokenService } from 'src/services';
import { useParams } from 'react-router-dom';
import SupportTicketsCreateTicket from './Views/SupportTicketsCreateTicket';
import { sortBy } from 'lodash';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
// ----------------------------------------------------------------------

export default function SupportTickets() {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const { id } = useParams();

  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const [ticketList, setTicketList] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [fullName, setFullName] = useState('');
  const [title, setTitle] = useState('');
  const [platforms, setPlatforms] = useState([]);
  const [username, setUsername] = useState('');
  const [status, setStatus] = useState([]);

  const [seeDetails, setSeeDetails] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState();

  const [currentUser, setCurrentUser] = useState();

  const [openCreateTicket, setOpenCreateTicket] = useState(false);

  const theme = useTheme();

  const isMobile = useMediaQuery('(max-width:768px)');
  const [filterModal, setFilterModal] = useState(false);
  const handleModal = () => {
    setFilterModal(true);
  };
  const handleCloseModal = () => {
    setFilterModal(false);
  };

  const TICKET_TABLE_HEAD = [
    {
      id: 'status',
      label: 'Durum',
      alignRight: false,
      format: (value) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {value === '1' ? (
            <span
              style={{
                backgroundColor: '#ABEFC6',
                padding: '4px 7px',
                borderRadius: '5px',
                textAlign: 'center',
              }}
            >
              {getStatusFromValue(value)}
            </span>
          ) : value === '2' ? (
            <span
              style={{
                backgroundColor: '#FEA3B4',
                padding: '4px 7px',
                borderRadius: '5px',
                textAlign: 'center',
              }}
            >
              {getStatusFromValue(value)}
            </span>
          ) : value === '4' ? (
            <span
              style={{
                backgroundColor: '#9155FD',
                padding: '4px 7px',
                borderRadius: '5px',
                textAlign: 'center',
              }}
            >
              {getStatusFromValue(value)}
            </span>
          ) : value === '3' || value === '5' ? (
            <span
              style={{
                backgroundColor: '#FFD6AE',
                padding: '4px 7px',
                borderRadius: '5px',
                textAlign: 'center',
              }}
            >
              {getStatusFromValue(value)}
            </span>
          ) : null}
        </div>
      ),
    },
    {
      id: 'nameLastname',
      label: 'Adı Soyadı',
      alignRight: false,
      sortBy: 'name',
      format: (value, row) => `${row.name} ${row.lastname}`,
    },
    {
      id: 'createdDateTime',
      label: 'Oluşturulma Tarihi',
      alignRight: false,
      format: (value) => moment.unix(value).format('DD/MM/YYYY HH:mm'),
    },
    {
      id: 'title',
      label: 'Başlık',
      alignRight: false,
      format: (value) => truncatedText(value),
    },
    {
      id: 'type',
      label: 'Tür',
      alignRight: false,
      format: (value) => getTitleFromValue(value),
    },
    {
      id: 'username',
      label: 'Kullanıcı Adı',
      alignRight: false,
    },
   
    {
      id: 'edit',
      label: '',
      alignRight: false,
      format: (value, row) => (
        <EditButton tooltip='İncele' icon={'ph:eye'} onClick={() => handleOpenDetails(row)} />
      ),
    },
  ];

  const supportTypes = [
    { value: 'MallLogisticsNet', title: 'MallLogisticsNet' },
    { value: 'Mall Logistics App', title: 'Mall Logistics App' },
    { value: 'Handsfree Store Panel', title: 'Handsfree Mağaza Panel' },
    { value: 'Handsfree Point Panel', title: 'Handsfree Nokta Panel' },
    { value: 'General', title: 'Genel Talep' },
    { value: 'Porter App', title: 'Porter App' },
    { value: 'Technical Support', title: 'Teknik Destek' },
    { value: 'Development', title: 'Geliştirme Talebi' },
    { value: 'Report Bug', title: 'Hata Bildirimi' },
  ];

  const statusTypes = [
    { value: '1', label: 'Tamamlandı' }, //yeşil
    // { value: '2', label: 'İptal Edildi' }, //kırmızı
    { value: '3', label: 'Bekliyor' }, //sarı
    { value: '4', label: 'Yapılıyor' }, //mor
    { value: '5', label: 'Cevap Bekliyor' }, //fuşya,lila gradient
  ];

  const getStatusFromValue = (value) => {
    const statusType = statusTypes.find((type) => type.value === value);
    return statusType ? statusType.label : null;
  };

  const getTitleFromValue = (value) => {
    const supportType = supportTypes.find((type) => type.value === value);
    return supportType ? supportType.title : null;
  };
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  function truncatedText(text) {
    const maxLength = 20;
    if (text.length > maxLength) {
      return <span>{text.substring(0, maxLength)}...</span>;
    }
    return <span style={{ width: '35%' }}>{text}</span>;
  }

  const handleResponse = (response) => {
    const { status, result } = response.data;
    if (status) {
      setTicketList(result);
      setOriginalData(result);
    } else {
      setTicketList([]);
      setOriginalData([]);
      toast.error('Destek talebi bulunamadı.');
    }
    setIsLoading(false);
  };

  const handleError = () => {
    setIsLoading(false);
    toast.error('Bir hata oluştu.');
  };

  const fetchTickets = (serviceFunction, ...args) => {
    serviceFunction(...args)
      .then(handleResponse)
      .catch(handleError);
  };

  const getTickets = () => {
    const currentUserInfo = tokenService.getUser();
    setCurrentUser(currentUserInfo);

    const { frontAuth, userID, type } = currentUserInfo;
    const { token } = parsedUser;

    if (frontAuth) {
      if (type === 'superadmin') {
        fetchTickets(Services.GetTicketsManager, token);
      } else if (
        !frontAuth.includes('technical-support-staff') &&
        !frontAuth.includes('technical-support-manager')
      ) {
        fetchTickets(Services.GetTicketsUser, token, userID);
      } else if (
        frontAuth.includes('technical-support-staff') &&
        !frontAuth.includes('technical-support-manager')
      ) {
        fetchTickets(Services.GetTicketsStaff, token, userID);
      } else if (frontAuth.includes('technical-support-manager')) {
        fetchTickets(Services.GetTicketsManager, token);
      }
    } else {
      if (type === 'superadmin') {
        fetchTickets(Services.GetTicketsManager, token);
      } else {
        fetchTickets(Services.GetTicketsUser, token, userID);
      }
    }
  };

  function getTicket() {
    Services.GetTicket(parsedUser.token, id)
      .then((response) => {
        if (response.data.status) {
          setSelectedTicket(response.data.result);
          setSeeDetails(true);
        } else {
          toast.error('Destek talebi bulunamadı.');
        }
      })
      .catch(() => {
        toast.error('Bir hata oluştu.');
      });
  }

  useEffect(() => {
    if (!id) {
      getTickets();
    } else {
      getTicket();
    }
  }, []);

  const handleChangePlatform = (event) => {
    const {
      target: { value },
    } = event;
    setPlatforms(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChangeStatus = (event) => {
    const {
      target: { value },
    } = event;
    setStatus(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const getFilteredTicketList = () => {
    let filteredTicketList;
    if (
      fullName ||
      (startDate && endDate) ||
      title ||
      platforms.length > 0 ||
      status.length > 0 ||
      username
    ) {
      filteredTicketList = originalData.filter((item) => {
        const nameMatch = fullName
          ? (item.name + ' ' + item.lastname)
            .toLowerCase()
            .includes(fullName.toLowerCase())
          : true;
        const usernameMatch = username
          ? item.username.toLowerCase().includes(username.toLowerCase())
          : true;
        const dateMatch =
          startDate && endDate
            ? moment.unix(item.createdDateTime).format('DD-MM-YYYY') >=
            moment(startDate).format('DD-MM-YYYY') &&
            moment.unix(item.createdDateTime).format('DD-MM-YYYY') <=
            moment(endDate).format('DD-MM-YYYY')
            : true;
        const titleMatch = title
          ? item.title.toLowerCase().includes(title.toLowerCase())
          : true;
        const platformMatch =
          platforms.length > 0 ? platforms.includes(item.type) : true;
        const statusMatch =
          status.length > 0 ? status.includes(item.status) : true;

        return (
          dateMatch &&
          nameMatch &&
          titleMatch &&
          platformMatch &&
          statusMatch &&
          usernameMatch
        );
      });
    } else {
      filteredTicketList = originalData;
    }

    setTicketList(filteredTicketList);
  };

  const handleOpenDetails = (ticket) => {
    setSeeDetails(true);
    setSelectedTicket(ticket);
  };

  const handleCloseDetails = () => {
    setSeeDetails(false);
    setSelectedTicket();
    getTickets();
  };

  const clearFilters = () => {
    setTicketList(originalData);
    setFullName('');
    setStartDate(null);
    setEndDate(null);
    setTitle('');
    setPlatforms([]);
    setStatus([]);
    setUsername('');
  };

  if (seeDetails) {
    return (
      <SupportTicketsConversation
        ticket={selectedTicket}
        handleCloseDetails={handleCloseDetails}
        getTickets={getTickets}
        seeDetails={seeDetails}
        id={id}
        token={parsedUser.token}
      />
    );
  } else if (openCreateTicket) {
    return (
      <SupportTicketsCreateTicket
        currentUser={currentUser}
        token={parsedUser.token}
        setOpenCreateTicket={setOpenCreateTicket}
        handleCloseDetails={handleCloseDetails}
      />
    );
  } else {
    return (
      <>
        <Helmet>
          <title> Teknik Destek Talepleri | Mall Logistics Panel </title>
        </Helmet>

        <Stack style={{ paddingInline: '25px' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Teknik Destek Talepleri
            </Typography>
            <AddEntityButton
              onClick={() => setOpenCreateTicket(true)}
              label={'Talep Oluştur'}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent={isMobile ? 'space-between' : 'end'}
            mb={5}
          >

            {isMobile ? <FilterViewButton variant="contained" onClick={handleModal} /> : null}



          </Stack>
          <Card>
            <CustomFilter modalType={'drawer'} filterModal={filterModal} handleCloseModal={handleCloseModal}>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                paddingY={2}
                paddingX={1}
                spacing={2}

              >
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="Kullanıcının Adı"
                    variant="outlined"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="E-mail / Kullanıcı Adı"
                    variant="outlined"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="Konu"
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>



          
              <Stack
                direction="row"
                width='100%'
                spacing={1}
              >
                <FormControl sx={{ flex: 1, minWidth: '49%' }}>
                  <InputLabel id="priority-store-label">Platform</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={platforms}
                    onChange={handleChangePlatform}
                    input={<OutlinedInput label="Platform" />}
                    MenuProps={MenuProps}
                  >
                    {supportTypes.map((type) => (
                      <MenuItem
                        key={type.value}
                        value={type.value}
                        style={getStyles(type.title, supportTypes, theme)}
                      >
                        {type.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ flex: 1, minWidth: '49%' }}>
                  <InputLabel id="priority-store-label">Talep Durumu</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={status}
                    onChange={handleChangeStatus}
                    input={<OutlinedInput label="Talep Durumu" />}
                    MenuProps={MenuProps}
                  >
                    {statusTypes.map((type) => (
                      <MenuItem
                        key={type.value}
                        value={type.value}
                        style={getStyles(type.label, statusTypes, theme)}
                      >
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
              >
                <FormControl sx={{ flex: 1, minWidth: '49%' }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Başlangıç Tarihi"
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      sx={{ flex: 1 }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ flex: 1, minWidth: '49%' }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Bitiş Tarihi"
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      sx={{ flex: 1 }}
                    />
                  </LocalizationProvider>
                </FormControl>


              </Stack>
              </Stack>
              <Divider sx={{ width: '100%', mb: 2 }} />

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <ClearButton onClick={clearFilters} />
                <FilterButton onClick={getFilteredTicketList} />
              </Stack>
            </CustomFilter>
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  padding: '50px',
                }}
              >
                <CircularProgress size={24} color="inherit" />
              </div>
            ) : (
              <DynamicTable
                data={ticketList}
                headers={TICKET_TABLE_HEAD}
                initialOrder={'createdDateTime'}
                initalOrderDirection={'desc'}
                isOrdered={true}
                rowOnClick={(row) => handleOpenDetails(row)}
                isMobile={isMobile}
                handleModal={handleModal}
                handleCloseModal={handleCloseModal}
              />
            )}

          </Card>
        </Stack>
      </>
    );
  }
}
