import React, { useEffect, useState } from 'react';
import { Backdrop, Card, CircularProgress, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import AddEntityButton from 'src/components/shared/AddEntityButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import Services from 'src/services/Services';
import AddEditCampaignModal from 'src/components/modals/AddEditCampaignModal';

export default function CampaignPage() {
    const parsedUser = JSON.parse(localStorage.getItem('user'));

    const [loading, setLoading] = useState(true);
    const [campaignList, setCampaignList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState();

    let TABLE_HEAD = [
        { id: 'title', label: 'Kampanya Adı' },
        { id: 'description', label: 'Açıklama', format: (value) => truncatedText(value) },
        { id: 'createdDateTime', label: 'Oluşturulma Tarihi', format: (value) => formatDate(value) },
        { id: 'start', label: 'Başlangıç Tarihi', format: (value) => formatDate(value) },
        { id: 'end', label: 'Bitiş Tarihi', format: (value) => formatDate(value) },
        { id: 'status', label: 'Durum', format: (value) => (value === "1" ? "Aktif" : "Pasif") },
    ];

    const formatDate = (date) => {
        const formattedDate = moment.unix(date).format('DD.MM.YYYY');
        return formattedDate;
    };

    function removeHtmlTags(str) {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    }

    function truncatedText(text) {
        let newText = removeHtmlTags(text);
        const maxLength = 20;
        if (newText.length > maxLength) {
            return <span>{newText.substring(0, maxLength)}...</span>;
        }
        return <span style={{ width: '35%' }}>{newText}</span>;
    }

    // Tarih kontrolü burada yapılacak
    const getRowStyle = (campaign) => {
        const endDate = moment.unix(campaign.end);
        const today = moment();
        const daysDiff = endDate.diff(today, 'days');

        if (daysDiff <= 0) {
            return { backgroundColor: '#eb4034', color: '#fff' }; // Geçmiş kampanyalar kırmızı
        } else if (daysDiff <= 3) {
            return { backgroundColor: '#eb9634', color: '#fff' }; // Bitimine 3 gün kalan kampanyalar turuncu
        } else {
            return {}; // Diğer kampanyalar için stil yok
        }
    };

    const handleCampaignAdd = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const getCampaigns = (token) => {
        setLoading(true);
        Services.GetCampaigns(token)
            .then((res) => {
                if (res.data.status) {
                    setCampaignList(res.data.result);
                    setLoading(false);
                } else {
                    toast.error("Kampanya bulunamadı.");
                    setLoading(false);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getCampaigns(parsedUser.token);
    }, []);

    const handleCampaignSelect = (campaign) => {
        setModalOpen(true)
        setSelectedCampaign(campaign);
    }

    return (
        <>
            <Helmet>
                <title> Kampanyalar | Mall Logistics Panel </title>
            </Helmet>

            <Stack style={{ paddingInline: '25px' }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Kampanyalar
                    </Typography>
                    <AddEntityButton
                        label={'Kampanya Ekle'}
                        icon={'ic:outline-add'}
                        onClick={handleCampaignAdd}
                    />
                </Stack>
                <Card>
                    {loading ? (
                        <Backdrop sx={{ color: '#fff', zIndex: 2, height: '100%' }} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    ) : (
                        <DynamicTable
                            data={campaignList}
                            headers={TABLE_HEAD}
                            isOrdered={true}
                            initialOrder={"createdDateTime"}
                            initalOrderDirection={"desc"}
                            rowStyles={getRowStyle} // Stil prop'u ekleniyor
                            rowOnClick={handleCampaignSelect}
                        />
                    )}
                </Card>
            </Stack>

            <AddEditCampaignModal
                open={modalOpen}
                onClose={handleCloseModal}
                getCampaigns={getCampaigns}
                campaign={selectedCampaign}
                setSelectedCampaign={setSelectedCampaign}
            />
        </>
    );
}
