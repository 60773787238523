import React, { useState, useEffect, useRef } from 'react';
import {
  Backdrop,
  CircularProgress,
  FormControl,
  Typography,
  Checkbox,
  FormControlLabel,
  ButtonBase,
  Stack,
  InputAdornment
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import api from 'src/services/BaseService';
import moment from 'moment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const AddEditAvmModal = ({
  open,
  onClose,
  isEdit,
  editData,
  mallList,
  isBackdropOpen,
}) => {

  const textAreaRef = useRef(null);

  const [showResultMessage, setShowResultMessage] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  let transportationToolsWithPrice = [];

  const [formData, setFormData] = useState({
    mallID: '',
    avmTitle: '',
    createAvmTitle: '',
    city: '',
    county: '',
    transportationTool: '',
    transportationPrice: '',
    transportationCurrency: '',
    hasMuhaberat: false,
    isParcelCargo: false,
    isSmallTool: false,
    isBigTool: false,
    isSuspendTool: false,
    isPallet: false,
    isMiddleTool: false,
    isStandartVehicle: false,

    parcelCargoPrice: '',
    smallToolPrice: '',
    bigToolPrice: '',
    suspendToolPrice: '',
    palletPrice: '',
    isMiddlePrice: '',
    standartVehiclePrice: '',

    parcelCargoCurrency: '',
    smallToolCurrency: '',
    bigToolCurrency: '',
    suspendToolCurrency: '',
    palletCurrency: '',
    isMiddleCurrency: '',
    standartVehicleCurrency: '',

    isPrimary: false,
  });
  const cargoList = [
    'Koli / Kargo',
    'Küçük Araç',
    'Büyük Araç',
    'Askılı Araç',
    'Palet',
    'Orta Araç',
    'Standart Araç'
  ];
  const cityList = [
    { id: 1, name: 'Adana' },
    { id: 2, name: 'Adıyaman' },
    { id: 3, name: 'Afyonkarahisar' },
    { id: 4, name: 'Ağrı' },
    { id: 5, name: 'Amasya' },
    { id: 6, name: 'Ankara' },
    { id: 7, name: 'Antalya' },
    { id: 8, name: 'Artvin' },
    { id: 9, name: 'Aydın' },
    { id: 10, name: 'Balıkesir' },
    { id: 11, name: 'Bilecik' },
    { id: 12, name: 'Bingöl' },
    { id: 13, name: 'Bitlis' },
    { id: 14, name: 'Bolu' },
    { id: 15, name: 'Burdur' },
    { id: 16, name: 'Bursa' },
    { id: 17, name: 'Çanakkale' },
    { id: 18, name: 'Çankırı' },
    { id: 19, name: 'Çorum' },
    { id: 20, name: 'Denizli' },
    { id: 21, name: 'Diyarbakır' },
    { id: 22, name: 'Edirne' },
    { id: 23, name: 'Elazığ' },
    { id: 24, name: 'Erzincan' },
    { id: 25, name: 'Erzurum' },
    { id: 26, name: 'Eskişehir' },
    { id: 27, name: 'Gaziantep' },
    { id: 28, name: 'Giresun' },
    { id: 29, name: 'Gümüşhane' },
    { id: 30, name: 'Hakkari' },
    { id: 31, name: 'Hatay' },
    { id: 32, name: 'Isparta' },
    { id: 33, name: 'Mersin' },
    { id: 34, name: 'İstanbul' },
    { id: 35, name: 'İzmir' },
    { id: 36, name: 'Kars' },
    { id: 37, name: 'Kastamonu' },
    { id: 38, name: 'Kayseri' },
    { id: 39, name: 'Kırklareli' },
    { id: 40, name: 'Kırşehir' },
    { id: 41, name: 'Kocaeli' },
    { id: 42, name: 'Konya' },
    { id: 43, name: 'Kütahya' },
    { id: 44, name: 'Malatya' },
    { id: 45, name: 'Manisa' },
    { id: 46, name: 'Kahramanmaraş' },
    { id: 47, name: 'Mardin' },
    { id: 48, name: 'Muğla' },
    { id: 49, name: 'Muş' },
    { id: 50, name: 'Nevşehir' },
    { id: 51, name: 'Niğde' },
    { id: 52, name: 'Ordu' },
    { id: 53, name: 'Rize' },
    { id: 54, name: 'Sakarya' },
    { id: 55, name: 'Samsun' },
    { id: 56, name: 'Siirt' },
    { id: 57, name: 'Sinop' },
    { id: 58, name: 'Sivas' },
    { id: 59, name: 'Tekirdağ' },
    { id: 60, name: 'Tokat' },
    { id: 61, name: 'Trabzon' },
    { id: 62, name: 'Tunceli' },
    { id: 63, name: 'Şanlıurfa' },
    { id: 64, name: 'Uşak' },
    { id: 65, name: 'Van' },
    { id: 66, name: 'Yozgat' },
    { id: 67, name: 'Zonguldak' },
    { id: 68, name: 'Aksaray' },
    { id: 69, name: 'Bayburt' },
    { id: 70, name: 'Karaman' },
    { id: 71, name: 'Kırıkkale' },
    { id: 72, name: 'Batman' },
    { id: 73, name: 'Şırnak' },
    { id: 74, name: 'Bartın' },
    { id: 75, name: 'Ardahan' },
    { id: 76, name: 'Iğdır' },
    { id: 77, name: 'Yalova' },
    { id: 78, name: 'Karabük' },
    { id: 79, name: 'Kilis' },
    { id: 80, name: 'Osmaniye' },
    { id: 81, name: 'Düzce' },
  ];
  const handleSave = () => {
    let isPrimaryBoolean;
    if (formData.hasMuhaberat === false) {
      isPrimaryBoolean = '0';
    } else {
      isPrimaryBoolean = '1';
    }
    if (formData.parcelCargoPrice && formData.parcelCargoCurrency) {
      transportationToolsWithPrice.push({
        title: cargoList[0],
        price: formData.parcelCargoPrice,
        currency: formData.parcelCargoCurrency,
      });
    }
    if (formData.smallToolPrice && formData.smallToolCurrency) {
      transportationToolsWithPrice.push({
        title: cargoList[1],
        price: formData.smallToolPrice,
        currency: formData.smallToolCurrency,
      });
    }
    if (formData.bigToolPrice && formData.bigToolCurrency) {
      transportationToolsWithPrice.push({
        title: cargoList[2],
        price: formData.bigToolPrice,
        currency: formData.bigToolCurrency,
      });
    }
    if (formData.suspendToolPrice && formData.suspendToolCurrency) {
      transportationToolsWithPrice.push({
        title: cargoList[3],
        price: formData.suspendToolPrice,
        currency: formData.suspendToolCurrency,
      });
    }
    if (formData.palletPrice && formData.palletCurrency) {
      transportationToolsWithPrice.push({
        title: cargoList[4],
        price: formData.palletPrice,
        currency: formData.palletCurrency,
      });
    }

    if (formData.isMiddlePrice && formData.isMiddleCurrency) {
      transportationToolsWithPrice.push({
        title: cargoList[5],
        price: formData.isMiddlePrice,
        currency: formData.isMiddleCurrency,
      });
    }

    if (formData.standartVehiclePrice && formData.standartVehicleCurrency) {
      transportationToolsWithPrice.push({
        title: cargoList[6],
        price: formData.standartVehiclePrice,
        currency: formData.standartVehicleCurrency,
      });
    }

    //const selectedAvm = mallList.find((mall) => mall.id === formData.mallID);

    if (isEdit) {
      const requestBody = {
        id: editData.id,
        title: formData.createAvmTitle,
        city: formData.city,
        county: formData.county,
        hasMuhaberat: formData.hasMuhaberat,
        transportationToolsWithPrice,
      };
      //console.log("requestBody", requestBody);
      api
        .post('/flow/malls-update', requestBody)
        .then((response) => {
          if (response.data.status) {
            // console.log(response.data)
            setResultMessage('Mağaza başarıyla Düzenlendi!');
            setShowResultMessage(true);
          } else {
            // console.log("error",response)
            setResultMessage('Mağaza Düzenlenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
            // toast.error(response.data.message);
          }
        })
        .catch((error) => {
          // console.log(error);
          // toast.error(error.message);
        });
      // console.log("requestBody", requestBody);
    } else {
      const requestBody = {
        title: formData.createAvmTitle,
        city: formData.city,
        county: formData.county,
        hasMuhaberat: formData.hasMuhaberat,
        transportationToolsWithPrice,
      };
      api
        .post('/flow/malls-create', requestBody)
        .then((response) => {
          if (response.data.status) {
            // console.log(response.data)
            setResultMessage('Mağaza başarıyla Eklendi!');
            setShowResultMessage(true);
          } else {
            // console.log("error",response)
            setResultMessage('Mağaza Eklenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
            // toast.error(response.data.message);
          }
        })
        .catch((error) => {
          // console.log(error);
          // toast.error(error.message);
        });
      // console.log("requestBody", requestBody);
    }
  };

  const allFieldsAreValid = () => {
    if (isEdit) {
      if (
        formData.mallID !== '' &&
        formData.city !== '' &&
        formData.county !== ''
      ) {
        return true;
      } else return false;
    } else {
      if (
        formData.createAvmTitle !== '' &&
        formData.city !== '' &&
        formData.county !== ''
      ) {
        return true;
      } else return false;
    }
  };

  const handleMallChange = async (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      mallID: value,
    }));
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === 'county' || name === 'createAvmTitle') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      return;
    }
  };
  const handleSwitchChange = () => {
    const { name, value } = event.target;

    if (name === 'hasMuhaberat') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: !formData.hasMuhaberat,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleCityChange = (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      city: value,
    }));
  };

  const handleClose = () => {
    setFormData({});
    onClose();
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const handleCloseMessage = () => {
    setShowResultMessage(false);
    setFormData({});
    onClose();
  };
  const handleCargoChange = async (event) => {
    const { name, value } = event.target;
    if (name === 'transportationTool') {
      setFormData((prevState) => ({
        ...prevState,
        transportationTool: value,
      }));
    } else if (
      name === 'transportationPrice' ||
      name === 'parcelCargoPrice' ||
      name === 'smallToolPrice' ||
      name === 'bigToolPrice' ||
      name === 'suspendToolPrice' ||
      name === 'palletPrice' ||
      name === 'isMiddlePrice' ||
      name === 'standartVehiclePrice'
    ) {
      let formattedValue = value.replace(/,/g, '.');
      if (formattedValue.split('.').length > 2) {
        formattedValue = formattedValue.slice(0, formattedValue.length - 1);
      }
      if (/^[0-9]*\.?[0-9]*$/.test(formattedValue)) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      }
    } else if (
      name === 'transportationCurrency' ||
      name === 'parcelCargoCurrency' ||
      name === 'smallToolCurrency' ||
      name === 'bigToolCurrency' ||
      name === 'suspendToolCurrency' ||
      name === 'palletCurrency' ||
      name === 'isMiddleCurrency' ||
      name === 'standartVehicleCurrency'
    ) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleIdCopy = (e) => {
    const value = textAreaRef.current.querySelector('input').value;
    navigator.clipboard.writeText(value)
    e.target.focus();

  };
  let transportationPrices = {
    parcelCargoPrice: '',
    smallToolPrice: '',
    bigToolPrice: '',
    suspendToolPrice: '',
    palletPrice: '',
    isMiddlePrice: '',
    standartVehiclePrice: '',
    //currency
    parcelCargoCurrency: '',
    smallToolCurrency: '',
    bigToolCurrency: '',
    suspendToolCurrency: '',
    palletCurrency: '',
    isMiddleCurrency: '',
    standartVehicleCurrency:''
  };
  if (editData?.transportation_tool_with_price !== false) {
    editData?.transportation_tool_with_price?.forEach(tool => {
      switch (tool.title) {
        case 'Koli / Kargo':
          transportationPrices.parcelCargoPrice = tool.price;
          transportationPrices.parcelCargoCurrency = tool.currency;
          break;
        case 'Küçük Araç':
          transportationPrices.smallToolPrice = tool.price;
          transportationPrices.smallToolCurrency = tool.currency;
          break;
        case 'Büyük Araç':
          transportationPrices.bigToolPrice = tool.price;
          transportationPrices.bigToolCurrency = tool.currency;
          break;
        case 'Askılı Araç':
          transportationPrices.suspendToolPrice = tool.price;
          transportationPrices.suspendToolCurrency = tool.currency;
          break;
        case 'Palet':
          transportationPrices.palletPrice = tool.price;
          transportationPrices.palletCurrency = tool.currency;
          break;
        case 'Orta Araç':
          transportationPrices.isMiddlePrice = tool.price;
          transportationPrices.isMiddleCurrency = tool.currency;
          break;
        case 'Standart Araç':
          transportationPrices.standartVehiclePrice = tool.price;
          transportationPrices.standartVehicleCurrency = tool.currency;
        default:
          break;
      }
    });
  }

  useEffect(() => {
    if (isEdit) {
      let isPrimaryBoolean;
      if (editData.hasMuhaberat === '1') {
        isPrimaryBoolean = true;
      } else {
        isPrimaryBoolean = false;
      }

      setFormData({
        avmTitle: '',
        mallID: editData.id,
        city: editData.city,
        county: editData.county,
        hasMuhaberat: isPrimaryBoolean,
        createAvmTitle: editData.title,
        isParcelCargo: editData?.transportation_tools?.includes('Koli / Kargo'),
        isSmallTool: editData?.transportation_tools?.includes('Küçük Araç'),
        isBigTool: editData?.transportation_tools?.includes('Büyük Araç'),
        isSuspendTool: editData?.transportation_tools?.includes('Askılı Araç'),
        isPallet: editData?.transportation_tools?.includes('Palet'),
        isMiddleTool: editData?.transportation_tools?.includes('Orta Araç'),
        isStandartVehicle: editData?.transportation_tools?.includes('Standart Araç'),

        parcelCargoPrice: transportationPrices.parcelCargoPrice,
        smallToolPrice: transportationPrices.smallToolPrice,
        bigToolPrice: transportationPrices.bigToolPrice,
        suspendToolPrice: transportationPrices.suspendToolPrice,
        palletPrice: transportationPrices.palletPrice,
        isMiddlePrice: transportationPrices.isMiddlePrice,
        standartVehiclePrice: transportationPrices.standartVehiclePrice,

        parcelCargoCurrency: transportationPrices.parcelCargoCurrency,
        smallToolCurrency: transportationPrices.smallToolCurrency,
        bigToolCurrency: transportationPrices.bigToolCurrency,
        suspendToolCurrency: transportationPrices.suspendToolCurrency,
        palletCurrency: transportationPrices.palletCurrency,
        isMiddleCurrency: transportationPrices.isMiddleCurrency,
        standartVehicleCurrency:transportationPrices.standartVehicleCurrency,

        transportationTool: '',
        transportationCurrency: '',
        isPrimary: false,
      });
    }
  }, []);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Backdrop sx={{ color: '#fff', zIndex: 2 }} open={isBackdropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <DialogTitle>AVM Listesi</DialogTitle>

        <DialogContent>
          <>
            {isEdit === true ? (
              <FormControl fullWidth margin="normal">
                <TextField
                  fullWidth
                  label="AVM Id"
                  name="avmListId"
                  value={editData.id}
                  margin="normal"
                  readOnly
                  ref={textAreaRef}
                  disabled={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button onClick={handleIdCopy}>
                          <ContentCopyIcon color="action" />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />


              </FormControl>
            ) : ''

            }

            <FormControl fullWidth margin="normal">

              <TextField
                fullWidth
                label="AVM/Plaza İsmi*"
                name="createAvmTitle"
                value={formData.createAvmTitle}
                onChange={handleTextFieldChange}
                margin="normal"
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Şehir Adı*</InputLabel>
              <Select
                sx={{ width: '100%' }}
                label="İl"
                value={formData.city}
                onChange={handleCityChange}
                name="city"
              >
                {cityList.map((city, index) => (
                  <MenuItem key={index} value={city.name}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="İlçe*"
              name="county"
              value={formData.county}
              onChange={handleTextFieldChange}
              margin="normal"
            />

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              paddingY={1}
              paddingX={1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isParcelCargo}
                    onChange={handleCheckboxChange}
                    name="isParcelCargo"
                  />
                }
                label={
                  <ButtonBase
                    onClick={() =>
                      setFormData({
                        ...formData,
                        isParcelCargo: !formData.isParcelCargo,
                      })
                    }
                    sx={{
                      borderRadius: 0,
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Typography>{cargoList[0]}</Typography>
                  </ButtonBase>
                }
                sx={{ flex: 1.3 }}
              />
              <TextField
                fullWidth
                label="Fiyat*"
                name="parcelCargoPrice"
                value={formData.parcelCargoPrice}
                onChange={handleCargoChange}
                margin="normal"
                sx={{ flex: 2 }}
                disabled={!formData.isParcelCargo}
              />
              <FormControl fullWidth margin="normal" sx={{ flex: 1.2 }}>
                <InputLabel>Para Birimi*</InputLabel>
                <Select
                  label="Para Birimi*"
                  value={formData.parcelCargoCurrency}
                  onChange={handleCargoChange}
                  name="parcelCargoCurrency"
                  disabled={!formData.isParcelCargo}
                >
                  <MenuItem value="TRY">TL</MenuItem>
                  <MenuItem value="EURO">€</MenuItem>
                  <MenuItem value="USD">$</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              paddingY={1}
              paddingX={1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isSmallTool}
                    onChange={handleCheckboxChange}
                    name="isSmallTool"
                  />
                }
                label={
                  <ButtonBase
                    onClick={() =>
                      setFormData({
                        ...formData,
                        isSmallTool: !formData.isSmallTool,
                      })
                    }
                    sx={{
                      borderRadius: 0,
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Typography>{cargoList[1]}</Typography>
                  </ButtonBase>
                }
                sx={{ flex: 1.3 }}
              />
              <TextField
                fullWidth
                label="Fiyat*"
                name="smallToolPrice"
                value={formData.smallToolPrice}
                onChange={handleCargoChange}
                margin="normal"
                sx={{ flex: 2 }}
                disabled={!formData.isSmallTool}
              />
              <FormControl fullWidth margin="normal" sx={{ flex: 1.2 }}>
                <InputLabel>Para Birimi*</InputLabel>
                <Select
                  label="Para Birimi*"
                  value={formData.smallToolCurrency}
                  onChange={handleCargoChange}
                  name="smallToolCurrency"
                  disabled={cargoList.length === 0 || !formData.isSmallTool}
                >
                  <MenuItem value="TRY">TL</MenuItem>
                  <MenuItem value="EURO">€</MenuItem>
                  <MenuItem value="USD">$</MenuItem>
                </Select>
              </FormControl>
            </Stack>


            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              paddingY={1}
              paddingX={1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isMiddleTool}
                    onChange={handleCheckboxChange}
                    name="isMiddleTool"
                  />
                }
                label={
                  <ButtonBase
                    onClick={() =>
                      setFormData({
                        ...formData,
                        isMiddleTool: !formData.isMiddleTool,
                      })
                    }
                    sx={{
                      borderRadius: 0,
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Typography>{cargoList[5]}</Typography>
                  </ButtonBase>
                }
                sx={{ flex: 1.3 }}
              />
              <TextField
                fullWidth
                label="Fiyat*"
                name="isMiddlePrice"
                value={formData.isMiddlePrice}
                onChange={handleCargoChange}
                margin="normal"
                sx={{ flex: 2 }}
                disabled={!formData.isMiddleTool}
              />
              <FormControl fullWidth margin="normal" sx={{ flex: 1.2 }}>
                <InputLabel>Para Birimi*</InputLabel>
                <Select
                  label="Para Birimi*"
                  value={formData.isMiddleCurrency}
                  onChange={handleCargoChange}
                  name="isMiddleCurrency"
                  disabled={!formData.isMiddleTool}
                >
                  <MenuItem value="TRY">TL</MenuItem>
                  <MenuItem value="EURO">€</MenuItem>
                  <MenuItem value="USD">$</MenuItem>
                </Select>
              </FormControl>
            </Stack>


            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              paddingY={1}
              paddingX={1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isBigTool}
                    onChange={handleCheckboxChange}
                    name="isBigTool"
                  />
                }
                label={
                  <ButtonBase
                    onClick={() =>
                      setFormData({
                        ...formData,
                        isBigTool: !formData.isBigTool,
                      })
                    }
                    sx={{
                      borderRadius: 0,
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Typography>{cargoList[2]}</Typography>
                  </ButtonBase>
                }
                sx={{ flex: 1.3 }}
              />
              <TextField
                fullWidth
                label="Fiyat*"
                name="bigToolPrice"
                value={formData.bigToolPrice}
                onChange={handleCargoChange}
                margin="normal"
                sx={{ flex: 2 }}
                disabled={!formData.isBigTool}
              />
              <FormControl fullWidth margin="normal" sx={{ flex: 1.2 }}>
                <InputLabel>Para Birimi*</InputLabel>
                <Select
                  label="Para Birimi*"
                  value={formData.bigToolCurrency}
                  onChange={handleCargoChange}
                  name="bigToolCurrency"
                  disabled={cargoList.length === 0 || !formData.isBigTool}
                >
                  <MenuItem value="TRY">TL</MenuItem>
                  <MenuItem value="EURO">€</MenuItem>
                  <MenuItem value="USD">$</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              paddingY={1}
              paddingX={1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isSuspendTool}
                    onChange={handleCheckboxChange}
                    name="isSuspendTool"
                  />
                }
                label={
                  <ButtonBase
                    onClick={() =>
                      setFormData({
                        ...formData,
                        isSuspendTool: !formData.isSuspendTool,
                      })
                    }
                    sx={{
                      borderRadius: 0,
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Typography>{cargoList[3]}</Typography>
                  </ButtonBase>
                }
                sx={{ flex: 1.3 }}
              />
              <TextField
                fullWidth
                label="Fiyat*"
                name="suspendToolPrice"
                value={formData.suspendToolPrice}
                onChange={handleCargoChange}
                margin="normal"
                sx={{ flex: 2 }}
                disabled={!formData.isSuspendTool}
              />
              <FormControl fullWidth margin="normal" sx={{ flex: 1.2 }}>
                <InputLabel>Para Birimi*</InputLabel>
                <Select
                  label="Para Birimi*"
                  value={formData.suspendToolCurrency}
                  onChange={handleCargoChange}
                  name="suspendToolCurrency"
                  disabled={cargoList.length === 0 || !formData.isSuspendTool}
                >
                  <MenuItem value="TRY">TL</MenuItem>
                  <MenuItem value="EURO">€</MenuItem>
                  <MenuItem value="USD">$</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              paddingY={1}
              paddingX={1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isPallet}
                    onChange={handleCheckboxChange}
                    name="isPallet"
                  />
                }
                label={
                  <ButtonBase
                    onClick={() =>
                      setFormData({ ...formData, isPallet: !formData.isPallet })
                    }
                    sx={{
                      borderRadius: 0,
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Typography>{cargoList[4]}</Typography>
                  </ButtonBase>
                }
                sx={{ flex: 1.3 }}
              />
              <TextField
                fullWidth
                label="Fiyat*"
                name="palletPrice"
                value={formData.palletPrice}
                onChange={handleCargoChange}
                margin="normal"
                sx={{ flex: 2 }}
                disabled={!formData.isPallet}
              />
              <FormControl fullWidth margin="normal" sx={{ flex: 1.2 }}>
                <InputLabel>Para Birimi*</InputLabel>
                <Select
                  label="Para Birimi*"
                  value={formData.palletCurrency}
                  onChange={handleCargoChange}
                  name="palletCurrency"
                  disabled={cargoList.length === 0 || !formData.isPallet}
                >
                  <MenuItem value="TRY">TL</MenuItem>
                  <MenuItem value="EURO">€</MenuItem>
                  <MenuItem value="USD">$</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              paddingY={1}
              paddingX={1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isStandartVehicle}
                    onChange={handleCheckboxChange}
                    name="isStandartVehicle"
                  />
                }
                label={
                  <ButtonBase
                    onClick={() =>
                      setFormData({ ...formData, isStandartVehicle: !formData.isStandartVehicle })
                    }
                    sx={{
                      borderRadius: 0,
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                      textAlign: 'left'
                    }}
                  >
                    <Typography>{cargoList[6]}</Typography>
                  </ButtonBase>
                }
                sx={{ flex: 1.3 }}
              />
              <TextField
                fullWidth
                label="Fiyat*"
                name="standartVehiclePrice"
                value={formData.standartVehiclePrice}
                onChange={handleCargoChange}
                margin="normal"
                sx={{ flex: 2 }}
                disabled={!formData.isStandartVehicle}
              />
              <FormControl fullWidth margin="normal" sx={{ flex: 1.2 }}>
                <InputLabel>Para Birimi*</InputLabel>
                <Select
                  label="Para Birimi*"
                  value={formData.standartVehicleCurrency}
                  onChange={handleCargoChange}
                  name="standartVehicleCurrency"
                  disabled={cargoList.length === 0 || !formData.isStandartVehicle}
                >
                  <MenuItem value="TRY">TL</MenuItem>
                  <MenuItem value="EURO">€</MenuItem>
                  <MenuItem value="USD">$</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 10,
              }}
            >
              <div style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: 10,
              }}>
                <Typography>Muhaberat var mı:</Typography>
                <Switch
                  checked={formData.hasMuhaberat}
                  onChange={handleSwitchChange}
                  name="hasMuhaberat"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>

              <div style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: 10,
              }}><Typography>Handsfree var mı:</Typography>
                <Switch
                  checked={formData.hasMuhaberat}
                  onChange={handleSwitchChange}
                  name="hasMuhaberat"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /></div>

            </div>

          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Geri
          </Button>
          {isEdit === true ? (
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              disabled={!allFieldsAreValid()}
            >
              Kaydet
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              disabled={!allFieldsAreValid() || (!formData.isParcelCargo && !formData.isSmallTool && !formData.isBigTool && !formData.isSuspendTool && !formData.isPallet && !formData.isMiddleTool && !formData.isStandartVehicle)}
            >
              Ekle
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={showResultMessage}
        onClose={handleCloseMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isEdit === true ? (
          <DialogTitle id="alert-dialog-title">
            {'Mağaza Düzenleme'}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title">{'Mağaza Ekleme'}</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {resultMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>Tamam</Button>{' '}
          {/* Fonksiyon olarak geçirildi */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEditAvmModal;
